
/* styles for desktop devices */
@media only screen and (min-width: 992px) {
    h1{
        color: darkgreen;
        font-size: 50px;
        font-weight: 800;
    }
    .Header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 5%;
        background-color: #F49B45;
    }
    .logo{
        width: 200px;
    }
    .logoutbutton{
        width: 110px;
        background-color: rgb(157, 19, 19);
        color: white;
        padding: 10px;
        border-radius: 4px;
        font-weight: 800;
        cursor: pointer;
        
    }
  }
  
  /* styles for tablet devices */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    h1{
        color: darkgreen;
        font-size: 30px;
        font-weight: 800;
    }
    .Header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 5%;
        background-color: #F49B45;
    }
    .logo{
        width: 200px;
    }
    .logoutbutton{
        width: 110px;
        background-color: rgb(157, 19, 19);
        color: white;
        padding: 10px;
        border-radius: 4px;
        font-weight: 800;
        cursor: pointer;
        
    }
  }
  
  /* styles for mobile devices */
  @media only screen and (max-width: 767px) {
    h1{
        color: darkgreen;
        font-size: 30px;
        font-weight: 800;
    }
    .Header{
        display: block;
        padding: 5px 5%;
        background-color: #F49B45;
    }
    .logo{
        width: 200px;
    }
    .logoutbutton{
        width: 110px;
        background-color: rgb(157, 19, 19);
        color: white;
        padding: 10px;
        border-radius: 4px;
        font-weight: 800;
        cursor: pointer;
        
    }
  }